.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.iframeWrapper {
  width: 100vw;
  height: 440px;
  max-width: 784px;
  min-height: 400px;
  max-height: 440px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin: 0 auto;
  padding: 0 16px;
  border: 8px;
  /* background-color: greenyellow; */
}

.iframe {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Remove default iframe border */
}